// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoFortMyersBeachCottageRentals(): Node {
  return <SeoPageTemplate title="Fort Myers Beach Cottage Rentals" />
}

export default SeoFortMyersBeachCottageRentals
